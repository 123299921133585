<template>
  <button
    class="flex justify-center mx-auto text-center rounded-3xl py-3 bg-secondary"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
button:disabled {
  @apply cursor-not-allowed opacity-50;
}
</style>
